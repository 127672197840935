import Glide from "./glide/conteudo/glide.min.js"
import sal from './sal/sal.js'


document.addEventListener('DOMContentLoaded', function () {
    console.log('loaded')
    setTimeout(iniciar, 2500);
});

document.addEventListener('scroll', initOnEvent);
document.addEventListener('mousemove', initOnEvent);
document.addEventListener('touchstart', initOnEvent);

function initOnEvent(event) {
    iniciar();
    event.currentTarget.removeEventListener(event.type, initOnEvent); // remove the event listener that got triggered
}

function initGtm(w, d, s, l, i) {
    w[l] = w[l] || []; w[l].push({
        'gtm.start':
            new Date().getTime(), event: 'gtm.js'
    }); var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
            'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
}

// (function (w, d, s, l, i) {
//     w[l] = w[l] || []; w[l].push({
//         'gtm.start':
//             new Date().getTime(), event: 'gtm.js'
//     }); var f = d.getElementsByTagName(s)[0],
//         j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
//             'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
// })(window, document, 'script', 'dataLayer', 'GTM-N94TQXW');

function iniciar() {
    console.log('Iniciar libs')
    if (window.DidInit) {
        return false;
    }
    window.DidInit = true; 

    initGtm(window, document, 'script', 'dataLayer', 'GTM-N94TQXW');

    sal({
        threshold: 0.01,
        once: false,
    });

    const opt = {
        type: 'carousel',
        startAt: 0,
        focusAt: 'center',
        autoplay: 4000,
        hoverpause: true,
        gap: 4,
    }
    const glide = new Glide('#slider-clientes', {
        ...opt,
        perView: 5,
        autoplay: 7000,
        breakpoints: {
            600: { perView: 1 },
            900: { perView: 3 },
        },
        peek: 50,
    })
    glide.on('run.after', function () {
        window.dispatchEvent(new Event('resize'));
    })
    glide.mount()

    var splide = new Glide('#slide-projetos', {
        ...opt,
        perView: 3,
        breakpoints: {
            600: { perView: 1 },
            900: { perView: 2 },
        }
    });
    splide.on('run.after', function () {
        window.dispatchEvent(new Event('resize'));
    })
    splide.mount();
}